<template>
  <section>
    <div class="back"></div>
    <div class="page">
      <div class="main">
        <div class="header">
          <div class="header_title">聚灿网管理系统</div>
          
          <div class="header_top"></div>
          <div class="header_bottom"></div>
        </div>
        <div class="body">
          <div class="body_item fa">
            <a-icon class="body_item_icon" type="user" />
            <input
              type="text"
              placeholder="请输入登录账号"
              v-model="data.username"
              class="body_item_input"
            />
          </div>
          <div class="body_item fa">
            <a-icon class="body_item_icon" type="lock" />
            <input
              type="password"
              ref="input"
              placeholder="请输入密码"
              v-model="data.password"
              class="body_item_input"
            />
            <a-icon
              class="body_item_icon"
              @click="changeInput"
              :type="inputType == 'password' ? 'eye-invisible' : 'eye'"
            />
          </div>
          <!-- <div class="body_code fa">
            <input
              type="text"
              class="body_code_input"
              placeholder="验证码"
              v-model="data.code"
            />
            <img @click="toggleCode" class="body_code_icon" :src="code" />
          </div> -->
          <a-button
            @click="login"
            class="bodt_btn"
            type="primary"
            :loading="loading"
          >
            <span v-if="!loading">立即登录 </span>
            <span v-if="loading">登录中 </span>
          </a-button>
        </div>
      </div>
    </div>
    <audio
      v-show="false"
      src="../assets/login.mp3"
      ref="audio"
      controls="controls"
    ></audio>
  </section>
</template>

<script>
import { login } from "@/assets/api/api";
import qs from "qs";
export default {
  data() {
    return {
      data: {
        username: "",
        password: "",
        code: "",
      },
      code: "",
      loading: false,
      inputType: "password",
    };
  },
  methods: {
    toggleCode() {
      var num = Math.ceil(Math.random() * 10);
      this.code = "http://nb.eyo88.cn/Login/verify?=" + num;
    },
    changeInput() {
      if (this.$refs.input.type == "password") {
        this.$refs.input.type = "text";
        this.inputType = "text";
      } else {
        this.$refs.input.type = "password";
        this.inputType = "password";
      }
    },
    login() {
      if (this.data.username == "") {
        this.$message.error("请输入账号");
      } else if (this.data.password == "") {
        this.$message.error("请输入密码");
      } else {
        this.loading = true;
        login(qs.stringify(this.data)).then((res) => {
          this.loading = false;
          if (res.data.code == "0") {
            this.$refs.audio.play();
            sessionStorage.setItem("user", JSON.stringify(res.data.data));
            sessionStorage.setItem("token", res.data.data.token);
            this.$message.success("登录成功");
            this.$router.push("/index/index");
          } else {
            this.$message.error(res.data.msg);
          }
        });
      }
    },
  },
  created() {},
  mounted() {
    this.toggleCode();
  },
  computed: {},
  watch: {},
};
</script>

<style lang="less" rel="stylesheet/less" scoped>
.back {
  position: absolute;
  height: 100%;
  width: 100%;
  background: url("../assets/images/page.png") no-repeat top center;
  background-size: cover;
  background-attachment: fixed;
}
.page {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
}
.main {
  width: 430px;
  height: 390px;
  overflow: hidden;
  box-shadow: 0 0 30px #999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto auto;
  position: absolute;
  overflow: hidden;
  border-radius: 12px;
}
.header {
  height: 117px;
  width: 100%;
  font-size: 28px;
  color: #fff;
  overflow: hidden;
  font-weight: 200;
  text-align: center;
  background-color: #148be4;
}
.header_title{
  margin-top: 20px;
}
.header_text {
  font-size: 16px;
  margin-top: 5px;
}
.header_top {
  display: inline-block;
  width: 94px;
  height: 94px;
  background: #fff;
  opacity: 0.1;
  border-radius: 50%;
  position: absolute;
  right: -16px;
  top: -16px;
}
.header_bottom {
  display: inline-block;
  width: 74px;
  height: 74px;
  background: #fff;
  opacity: 0.1;
  border-radius: 0 74px 0 0;
  position: absolute;
  left: 0;
  top: 43px;
}
.body {
  padding: 0 50px 50px 50px;
  background: #fff;
}
.body_item {
  padding: 30px 0 15px 0;
  border-bottom: 1px solid #d1dde8;
}
.body_item_icon {
  font-size: 22px;
  display: inline-block;
  color: #d1dde8;
  vertical-align: middle;
}
.body_item_input {
  width: 283px;
  border: none;
  font-size: 14px;
  outline: 0;
  vertical-align: middle;
  display: inline-block;
  margin-left: 10px;
  border: none;
}
.body_code {
  padding: 30px 0 0 0;
}
.body_code_input {
  width: 190px;
  border: none;
  font-size: 14px;
  outline: 0;
  padding-bottom: 15px;
  vertical-align: middle;
  display: inline-block;
  border-bottom: 1px solid #d1dde8;
}
.body_code_icon {
  height: 36px;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
  width: 110px;
  margin-left: 30px;
}
.bodt_btn {
  width: 288px;
  height: 40px;
  background-color: #1e9fff;
  border-radius: 16px;
  margin: 50px auto 0;
  display: block;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0;
  cursor: pointer;
  border: none;
}
input::-webkit-input-placeholder {
  color: #a6aebf;
}
</style>